import ApiService from '@/common/api.service';

const endPoints = {
	getcompanydomaingriddata: 'admin/getcompanydomaingriddata',
	deletecompanydomain: 'admin/deletecompanydomain',
	addcompanydomain: 'admin/addcompanydomain',
};

export const DOMAIN_GRID_DATA = 'companydomaingriddata';
export const DOMAIN_REMOVE_LISTED = 'deletecompanydomain';
export const DOMAIN_ADD = 'addcompanydomain';

const state = {
	[DOMAIN_GRID_DATA]: [],
};

const getters = {
	[DOMAIN_GRID_DATA]: (state) => state[DOMAIN_GRID_DATA],
};

const actions = {
	[DOMAIN_GRID_DATA](context, payload) {
		return ApiService.post(endPoints.getcompanydomaingriddata, payload).then((response) => {
			context.commit(DOMAIN_GRID_DATA, response.data || response);
		});
	},
	[DOMAIN_REMOVE_LISTED](context, payload) {
		return ApiService.post(endPoints.deletecompanydomain, payload).then(() => {
			context.commit(DOMAIN_REMOVE_LISTED, payload);
		});
	},
	[DOMAIN_ADD](context, payload) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.addcompanydomain, payload)
				.then((response) => {
					context.commit(DOMAIN_ADD, response);
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
};

const mutations = {
	[DOMAIN_GRID_DATA](state, data) {
		state[DOMAIN_GRID_DATA] = data;
	},
	[DOMAIN_REMOVE_LISTED](state, payload) {
		state[DOMAIN_GRID_DATA] = state[DOMAIN_GRID_DATA].filter((item) => item.id !== payload.id);
	},
	[DOMAIN_ADD](state, data) {
		state[DOMAIN_GRID_DATA].push(data);
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
