import ApiService from '@/common/api.service';

export const INIT_COMPANY = 'initCompany';
export const SET_COMPANY = 'setCompany';
export const FETCH_SITE_SETTINGS = 'fetchSiteSettings';
export const UPDATE_COMPANY = 'updateCompany';
export const FETCH_SUPERVISORS = 'fetchSupervisorList';

// general settings
export const UPDATE_SOCIAL_MEDIA = 'updateSocialMedia';
export const UPDATE_FAQ = 'updateFaq';
export const UPDATE_BUSINESS_STEP = 'updateBusinessStep';
export const UPDATE_CAR_RESERVATION_SETTINGS = 'updateCarReservationSettings';
export const UPDATE_CAR_RESERVATION_STEP = 'updateCarReservationStep';
export const UPDATE_CAR_RESERVATION_FAQ = 'updateCarReservationFaq';
export const UPDATE_CONNECTION_SETTING = 'updateConnectionSetting';
export const UPDATE_COMPANY_INFO = 'updateCompanyInfo';
export const UPDATE_WIZARD_SETTING = 'updateWizardSetting';
export const UPDATE_SITE_SETTING = 'updateSiteSetting';
export const UPDATE_SITE_TEMPLATE = 'updateSiteTemplate';
export const UPDATE_CREDIT_SETTING = 'updateCreditSetting';
export const UPDATE_WORKHOURS = 'updateWorkHours';
export const UPDATE_QUICK_LINK = 'updateQuickLink';
export const BLACK_LIST_GRID_DATA = 'blackListGridData';
export const BLACK_LIST_REMOVE_LISTED = 'deleteBlackList';
export const BLACK_LIST_ADD = 'addBlackList';
export const LOCATION_GRID_DATA = 'locationGridData';
export const LOCATION_REMOVE_LISTED = 'deleteLocation';
export const LOCATION_GET_PRESET = 'getLocationPreset';
export const LOCATION_ADD = 'saveLocation';
export const GENERATE_SITEMAP = 'generateSiteMap';

export const FETCH_GET_LIST = "getlist";

const endPoints = {
	getCms: 'company/getcms',
	getPreset: 'company/getpreset',
	update: 'company/update',
	getSupervisorList: 'company/getsupervisorlist',

	// general settings
	updateSocialMedia: 'company/updatesocialmedia',
	updateFaq: 'company/updatefaq',
	updateBusinessStep: 'company/updatebusinessstep',
	updateCarReservationStep: 'company/updatecarreservationstep',
	updateCarReservationFaq: 'company/updatecarreservationfaq',
	updateCarReservationSettings: 'company/updatecarreservationsettings',
	updateConnectionSetting: 'company/updateconnectionsetting',
	updateCompanyInfo: 'company/updatecompanyinfo',
	updateWizardSetting: 'company/updatewizardsetting',
	updateSiteSetting: 'company/updatesitesetting',
	updateSiteTemplate: 'company/updatesitetemplate',
	updateCreditSetting: 'company/updatecreditsetting',
	updateWorkHours: 'company/updateworkhours',
	updateQuickLink: 'company/updatequicklink',
	getBlackList: 'company/getblacklistgriddata',
	deleteBlackList: 'company/deleteblacklist',
	addBlackList: 'company/addblacklist',
	getLocations: 'company/getlocationgriddata',
	deleteLocation: 'company/deletelocation',
	getLocationPreset: 'company/getlocationpreset',
	saveLocation: 'company/savelocation',
	generateSiteMap: 'company/generatesitemap',
	getlist: 'company/getlist'
};

const state = () => ({
	site: null,
	settings: null,
	supervisors: [],
	blackList: {
		data: [],
		count: 0,
	},
	locations: {
		data: [],
		count: 0,
	},
	companyUsers: [],
	companyList: []
});

const filterPagesHierarchically = (pages, key, orderField) =>
	pages
		.filter((page) => page[key]) // Key'e göre filtreleme (örn: showInHeader, showInFooter)
		.map((page) => {
			const filteredChildren =
				page.children && page.children.length > 0 ? filterPagesHierarchically(page.children, key, orderField) : [];
			return {
				...page,
				children: filteredChildren, // Alt sayfaları sıralanmış olarak ekle
			};
		})
		.sort((a, b) => a[orderField] - b[orderField]); // Üst sayfaları sıralama

const flattenAllPages = (pages) =>
	pages.flatMap((page) => {
		const flattenedChildren = page.children && page.children.length > 0 ? flattenAllPages(page.children) : [];
		return [{ ...page, children: [] }, ...flattenedChildren];
	});

const getters = {
	filteredPages: (state, getters) => (key) => {
		const pages = getters.getPages;
		return pages ? filterPagesHierarchically(pages, key) : [];
	},
	headerPages: (state, getters) => {
		const pages = getters.getPages;
		return pages ? filterPagesHierarchically(pages, 'showInHeader', 'headerOrder') : [];
	},
	footerPages: (state, getters) => {
		const pages = getters.getPages;
		if (!pages) return [];
		const allPages = flattenAllPages(pages);
		const footerPages = allPages.filter((page) => page.showInFooter).sort((a, b) => a.footerOrder - b.footerOrder);
		return footerPages;
	},
	getPages: (state) => state.site?.pages,
	getSite: (state, getters) =>
		getters.getPreviewSite !== null && typeof getters.currentRoute.query.preview !== 'undefined'
			? getters.getPreviewSite
			: state.site,
	darkTemplate: (state, getters) =>
		(getters.getPreviewSite !== null && typeof getters.currentRoute.query.preview !== 'undefined'
			? getters.getPreviewSite?.templateCode
			: state.site?.templateCode) === 'dark-one',
	templateHexColor: (state, getters) =>
		getters.getPreviewSite !== null && typeof getters.currentRoute.query.preview !== 'undefined'
			? getters.getPreviewSite?.templateColor.clrHex
			: state.site?.templateColor.clrHex,
	templateTextHexColor: (state, getters) =>
		getters.getPreviewSite !== null && typeof getters.currentRoute.query.preview !== 'undefined'
			? getters.getPreviewSite?.templateTextColor
			: state.site?.templateTextColor,
	buttonBackColor: (state, getters) =>
		getters.getPreviewSite !== null && typeof getters.currentRoute.query.preview !== 'undefined'
			? getters.getPreviewSite?.buttonStyle.color
			: state.site?.buttonStyle.color,
	buttonTextColor: (state, getters) =>
		getters.getPreviewSite !== null && typeof getters.currentRoute.query.preview !== 'undefined'
			? getters.getPreviewSite?.buttonStyle.textColor
			: state.site?.buttonStyle.textColor,
	headerBackColor: (state, getters) =>
		getters.getPreviewSite !== null && typeof getters.currentRoute.query.preview !== 'undefined'
			? getters.getPreviewSite?.headerStyle.color
			: state.site?.headerStyle.color,
	headerTextColor: (state, getters) =>
		getters.getPreviewSite !== null && typeof getters.currentRoute.query.preview !== 'undefined'
			? getters.getPreviewSite?.headerStyle.textColor
			: state.site?.headerStyle.textColor,
	footerBackColor: (state, getters) =>
		getters.getPreviewSite !== null && typeof getters.currentRoute.query.preview !== 'undefined'
			? getters.getPreviewSite?.footerStyle.color
			: state.site?.footerStyle.color,
	footerTextColor: (state, getters) =>
		getters.getPreviewSite !== null && typeof getters.currentRoute.query.preview !== 'undefined'
			? getters.getPreviewSite?.footerStyle.textColor
			: state.site?.footerStyle.textColor,

	settings: (state) => state.settings,
	company: (state) => state.settings?.company,
	templates: (state) => state.settings?.templates,
	socialMedias: (state) => state.settings?.socialMediaTypeList,
	cities: (state) => state.settings?.cityList,
	supervisors: (state) => state.supervisors,
	// eslint-disable-next-line no-unused-vars
	hasListing: (state) => state.site.hasListing,
	hasLeadgenB: (state) => state.site.hasLeadgenB,
	showFilterCount: (state) => state.site.showListingCount,
	showCredit: (state) => state.site.showCredit,
	chatbot: (state) => ({
		active: state.site?.chatBot.isChatBot,
		code: state.site?.chatBot.chatBotCode,
		id: state.site?.chatBot.chatBotProviderId,
	}),
	wizardBarSize: (state) => state.site.wizardBarSize,
	hasCarReservation: (state) => state.site?.carReservationSettings.configs.isActive,
	carReservationSettings: (state) => state.site?.carReservationSettings,

	blackListData: (state) => state.blackList.data,
	blackListDataTotalCount: (state) => state.blackList.count,

	locationGridData: (state) => state.locations.data,
	locationGridDataTotalCount: (state) => state.locations.count,

	companyUsers: (state) => state.companyUsers,
	showListingCarMenu: (state) => state.site.showListingCarMenu && state.site.hasListing,
	hasThumbImgZoom: (state) => state.site.hasThumbImgZoom,

	companyList: (state) => state.companyList,
};

const actions = {
	// sitemap
	// eslint-disable-next-line no-unused-vars
	[GENERATE_SITEMAP](context) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.generateSiteMap)
				.then((response) => {
					context.commit(GENERATE_SITEMAP, response);
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	// general settings
	[UPDATE_SITE_SETTING](context, payload) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.updateSiteSetting, payload)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[UPDATE_SOCIAL_MEDIA](context, payload) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.updateSocialMedia, payload)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[UPDATE_FAQ](context, payload) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.updateFaq, payload)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[UPDATE_BUSINESS_STEP](context, payload) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.updateBusinessStep, payload)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[UPDATE_CAR_RESERVATION_SETTINGS](context, payload) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.updateCarReservationSettings, payload)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[UPDATE_CAR_RESERVATION_STEP](context, payload) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.updateCarReservationStep, payload)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[UPDATE_CAR_RESERVATION_FAQ](context, payload) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.updateCarReservationFaq, payload)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[UPDATE_CONNECTION_SETTING](context, payload) {
		return new Promise((resolve, reject) => {
			const newPayload = {
				...payload,
				staffContactList: payload.staffContactList.map((x) => ({
					phone: `90${x.phone}`,
					eMail: x.eMail,
				})),
			};
			ApiService.post(endPoints.updateConnectionSetting, newPayload)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[UPDATE_COMPANY_INFO](context, payload) {
		return new Promise((resolve, reject) => {
			const newPayload = {
				...payload,
				priceMarginList: payload.priceMarginList.map((x) => ({
					companyId: x.companyId,
					priceMarginRate: x.priceMarginRate,
				})),
			};
			ApiService.post(endPoints.updateCompanyInfo, newPayload)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[UPDATE_WIZARD_SETTING](context, payload) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.updateWizardSetting, payload)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[UPDATE_CREDIT_SETTING](context, payload) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.updateCreditSetting, payload)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[UPDATE_SITE_TEMPLATE](context, payload) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.updateSiteTemplate, payload)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[UPDATE_QUICK_LINK](context, payload) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.updateQuickLink, payload)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[BLACK_LIST_GRID_DATA](context, payload) {
		ApiService.post(endPoints.getBlackList, payload).then((response) => {
			context.commit(BLACK_LIST_GRID_DATA, response);
		});
	},
	[BLACK_LIST_REMOVE_LISTED](context, payload) {
		ApiService.post(endPoints.deleteBlackList, payload.id).then(() => {
			context.commit(BLACK_LIST_REMOVE_LISTED, payload);
		});
	},
	[BLACK_LIST_ADD](context, payload) {
		return new Promise((resolve, reject) => {
			const newPayload = {
				...payload,
				phoneNumber: `90${payload.phoneNumber}`,
			};
			ApiService.post(endPoints.addBlackList, newPayload)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[LOCATION_GRID_DATA](context, payload) {
		ApiService.post(endPoints.getLocations, payload).then((response) => {
			context.commit(LOCATION_GRID_DATA, response);
		});
	},
	[LOCATION_REMOVE_LISTED](context, payload) {
		ApiService.post(endPoints.deleteLocation, payload.id).then(() => {
			context.commit(LOCATION_REMOVE_LISTED, payload);
		});
	},
	[LOCATION_GET_PRESET](context, payload) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.getLocationPreset, payload.id)
				.then((response) => {
					context.commit(LOCATION_GET_PRESET, response);
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[LOCATION_ADD](context, payload) {
		return new Promise((resolve, reject) => {
			const newPayload = {
				...payload,
				phone: `90${payload.phone}`,
			};
			ApiService.post(endPoints.saveLocation, newPayload)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[FETCH_GET_LIST](context, payload) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.getlist, payload.id)
				.then((response) => {
					context.commit(FETCH_GET_LIST, response);
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	[FETCH_SUPERVISORS](context) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.getSupervisorList)
				.then((data) => {
					context.commit(FETCH_SUPERVISORS, data);
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[INIT_COMPANY](context) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.getCms)
				.then((response) => {
					context.commit(SET_COMPANY, response);
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[FETCH_SITE_SETTINGS](context) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.getPreset)
				.then((response) => {
					context.commit(FETCH_SITE_SETTINGS, response);
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[UPDATE_COMPANY](context, payload) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.update, payload)
				.then(() => {
					context.commit(UPDATE_COMPANY, payload);
					context
						.dispatch(INIT_COMPANY)
						.then(() => {
							resolve();
						})
						.catch((error) => {
							reject(error);
						});
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[UPDATE_WORKHOURS](context, payload) {
		return new Promise((resolve, reject) => {
			ApiService.post(endPoints.updateWorkHours, payload)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
};

const mutations = {
	[GENERATE_SITEMAP](state, status) {
		state.site.hasSiteMap = status;
	},
	[FETCH_SUPERVISORS](state, data) {
		state.supervisors = data;
	},
	[SET_COMPANY](state, response) {
		state.site = response;
		// const buttonStyle = {
		// 	color: '#1E9404',
		// 	textColor: '#fffffff',
		// };

		// state.site = { ...response, buttonStyle };
	},
	[FETCH_SITE_SETTINGS](state, response) {
		state.settings = response;

		// const cmpButtonStyle = {
		// 	color: '#1E9404',
		// 	textColor: '#fffffff',
		// };

		// state.settings.company = {
		// 	...state.settings.company,
		// 	cmpButtonStyle,
		// };
	},
	[UPDATE_COMPANY](state, response) {
		state.settings.company = response;
	},
	[BLACK_LIST_REMOVE_LISTED](state, car) {
		const index = state.blackList.data.indexOf(car);
		if (index > -1) {
			state.blackList.data.splice(index, 1);
			state.blackList.count -= 1;
		}
	},
	[BLACK_LIST_GRID_DATA](state, response) {
		state.blackList.data = response.data;
		state.blackList.count = response.totalRecordCount;
	},
	[LOCATION_GRID_DATA](state, response) {
		state.locations.data = response.data;
		state.locations.count = response.totalRecordCount;
	},
	[LOCATION_REMOVE_LISTED](state, location) {
		const index = state.locations.data.indexOf(location);
		if (index > -1) {
			state.locations.data.splice(index, 1);
			state.locations.count -= 1;
		}
	},
	[LOCATION_GET_PRESET](state, response) {
		state.companyUsers = response.companyUsers;
	},
	[FETCH_GET_LIST](state, response) {
		state.companyList = response;
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
